import angular, { INgModelController, IScope } from 'angular';
import { SharedAngular } from '../../@types/sharedAngular';
import { Services } from '../../@types/services';
import IFormField from '@Shared.Angular/@types/core/contracts/queryModel/card/formField';
import { IFormModel } from '../form-controller';

interface IFieldControllerScope extends IScope {
  form: IFormModel;
  field: IFieldControllerField;
  showLogoFields: boolean;
  deselectIfSelected: (watchField: IFieldControllerField, val) => void;
  applyFieldConditions: (triggerField, fieldType) => void;
  triggerFieldConditions: (field) => void;
  populateNotifyFields: (watchField: IFieldControllerField) => void;
  setModifiedState: (changedFiled) => void;
}

angular.module('fg').controller('fgFieldController', [
  'APP_CONFIG',
  '$scope',
  'flowinglyConstants',
  'fgUtils',
  'pubsubService',
  'currencyService',
  'flowinglyFormulaService',
  'sessionService',
  'conditionalFormService',
  'fieldService',
  'busyService',
  function (
    APP_CONFIG: SharedAngular.APP_CONFIG,
    $scope: IFieldControllerScope,
    flowinglyConstants: SharedAngular.FlowinglyConstants,
    fgUtils: Services.FgUtils,
    pubsubService: SharedAngular.PubSubService,
    currencyService: SharedAngular.CurrencyService,
    flowinglyFormulaService: SharedAngular.FlowinglyFormulaService,
    sessionService: SharedAngular.SessionService,
    conditionalFormService: SharedAngular.ConditionalFormService,
    fieldService: SharedAngular.FieldService,
    busyService: SharedAngular.BusyService
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    let _form: IFormModel;
    let _field: IFieldControllerField;

    this.init = function (
      fgFormCtrl,
      fieldSchema: IFormField,
      editMode: boolean
    ) {
      self.initForm(fgFormCtrl);
      self.initField(fieldSchema, editMode);
      self.initDefaultData(fieldSchema, editMode);

      $scope.form = _form;
      $scope.field = _field;
      $scope.field.stepId = fgFormCtrl != null ? fgFormCtrl.stepId : '';
      if (_field.schema.type === flowinglyConstants.formFieldType.IMAGE) {
        sessionService.getSetting('ShowLogoFields').then((value) => {
          if (value === undefined) {
            // Public form anon user can't get the business setting
            // so we only hide logo if $scope.showLogoFields===false or they will never see it
            // Flow must be re-published to remove the logo
            return;
          }
          $scope.$applyAsync(() => {
            $scope.showLogoFields = (value && value.toLowerCase()) === 'true';
          });
        });
      }

      $scope.$on('$destroy', function () {
        conditionalFormService.emptyFieldsArray();
        clearTimeout(self.fieldChangeTimeout);
      });

      $scope.deselectIfSelected = function (
        watchField: IFieldControllerField,
        val
      ) {
        if (
          !watchField.state.$recentValue ||
          watchField.state.$recentValue != val
        ) {
          watchField.state.$recentValue = val;
        } else {
          watchField.state.$recentValue = null;
          $scope.form.data[watchField.name] = null;
        }

        $scope.populateNotifyFields(watchField);
      };

      $scope.applyFieldConditions = function (triggerField, fieldType) {
        if (APP_CONFIG.cfEnableConditionalRules === false) {
          return;
        }
        conditionalFormService.refreshUI(triggerField, _form);
      };

      $scope.triggerFieldConditions = function (field) {
        $scope.applyFieldConditions(field, $scope.fieldSchema.type);
      };

      $scope.populateNotifyFields = function (
        watchField: IFieldControllerField
      ) {
        $scope.applyFieldConditions(watchField, $scope.fieldSchema.type);
        let watchFieldValue;

        switch ($scope.fieldSchema.type) {
          case flowinglyConstants.formFieldType.CURRENCY:
            watchFieldValue = currencyService.parseNumber(
              $scope.form.data[watchField.name]
            );
            break;
          case flowinglyConstants.formFieldType.LOOKUP: {
            const lookupConfigValue = $scope.field.schema?.lookupConfig?.value;
            const islookupPreviousFieldType =
              lookupConfigValue ===
                flowinglyConstants.dynamicActorTypeNames.INITIATOR ||
              lookupConfigValue ===
                flowinglyConstants.lookupPreviousFieldTypeAndText
                  .CurrentActorEmail ||
              lookupConfigValue ===
                flowinglyConstants.lookupPreviousFieldTypeAndText
                  .CurrentActorName;

            // check if the field is already present in the form data (to avoid adding unnecessary fields) and the value is not null
            if (
              $scope.form?.data &&
              watchField.name in $scope.form.data &&
              !$scope.form?.data[watchField.name] &&
              (lookupConfigValue ===
                flowinglyConstants.lookupPreviousFieldTypeAndText
                  .CurrentActorEmail ||
                lookupConfigValue ===
                  flowinglyConstants.lookupPreviousFieldTypeAndText
                    .CurrentActorName)
            ) {
              $scope.form.data[watchField.name] = watchFieldValue =
                $scope.form._fields.find(
                  (x) => x.name == $scope.field.name
                )?.value;
            }

            if (
              $scope.field.schema.lookupConfig &&
              !islookupPreviousFieldType
            ) {
              if ($scope.field.schema.lookupConfig.userObject) {
                if ($scope.field.schema.lookupConfig.userObject.length > 0) {
                  $scope.form.data[watchField.name] = watchFieldValue =
                    $scope.field.schema.lookupConfig.userObject
                      .map(function (uo) {
                        return uo.text;
                      })
                      .join(', ');
                }
              } else {
                $scope.form.data[watchField.name] = '';
              }
            } else {
              watchFieldValue =
                $scope.form.values && $scope.form.values[watchField.name]
                  ? $scope.form.values[watchField.name]
                  : $scope.form.data[watchField.name];
            }
            break;
          }
          default:
            watchFieldValue =
              $scope.form.values && $scope.form.values[watchField.name]
                ? $scope.form.values[watchField.name]
                : $scope.form.data[watchField.name];
            break;
        }
        // for manually dropdown, need use text rather than value for query the API
        if (
          (!watchField.schema.dataSource ||
            watchField.schema.dataSource === 'manually') &&
          watchField.schema.options &&
          watchField.schema.options.length > 0
        ) {
          const matchOption = watchField.schema.options.find(function (o) {
            return o.value === watchFieldValue;
          });

          if (matchOption) {
            watchFieldValue = matchOption.text;
          }
        }

        if (
          watchField.schema.notifyFields &&
          watchField.schema.notifyFields.length > 0
        ) {
          if (!watchFieldValue) {
            // reset options
            watchField.schema.notifyFields.forEach(function (f) {
              setNotifyFieldOptions(f.fieldName, [], f.tableColumnId);
            });
            return;
          }
          const requestPayload = angular.copy(
            watchField.schema.notifyFields,
            []
          );
          for (const field of requestPayload) {
            let watchFieldName = '';
            if (
              field.dbDataSource &&
              field.dbDataSource.filters &&
              field.dbDataSource.filters.length > 0
            ) {
              watchFieldName = field.dbDataSource.filters[0].value;
              field.dbDataSource.filters[0].value = watchFieldValue.value
                ? watchFieldValue.value
                : watchFieldValue;
            }
            const matchField = $scope.form.schema.fields.find(function (sf) {
              return sf.name === field.fieldName;
            });

            if (matchField) {
              field.Searchable = matchField.searchable;
              field.SearchablePageSize =
                flowinglyConstants.searchableComboPageSize;
              if (watchFieldName) {
                const lookupFiled = $scope.form.schema.fields.find(function (
                  sf
                ) {
                  return (
                    sf.name === watchFieldName &&
                    sf.type === flowinglyConstants.formFieldType.LOOKUP
                  );
                });
                if (lookupFiled) {
                  field.previousFieldLookUpConfig = lookupFiled.lookupConfig;
                }
              }
            }
            field.flowModelId = $scope.fieldSchema.publicForm;
          }

          const optionsPromise = fieldService
            .getFieldOptions($scope.fieldSchema.publicForm, requestPayload)
            .then(function (results) {
              results.forEach(function (result) {
                setNotifyFieldOptions(
                  result.fieldName,
                  result.options,
                  result.tableColumnId
                );
              });
            });
          busyService.addPromise(optionsPromise);
        }
        // Update formula values
        if (typeof $scope.form.schema !== 'undefined') {
          for (let i = 0; i < $scope.form.schema.fields.length; i++) {
            const field = $scope.form.schema.fields[i];

            // update formulas in tables
            if (
              field.type === flowinglyConstants.formFieldType.TABLE &&
              field.tableSchema
            ) {
              const tableSchema = JSON.parse(field.tableSchema);
              if (
                tableSchema.some(
                  (s) => s.type === flowinglyConstants.tableCellType.FORMULA
                )
              ) {
                const tableDataJson = $scope.form.data[field.name];
                if (tableDataJson) {
                  const tableData = JSON.parse(tableDataJson);
                  for (const cellSchema of tableSchema.filter(
                    (s) => s.type === flowinglyConstants.tableCellType.FORMULA
                  )) {
                    for (const row of tableData.rows) {
                      // if there is no data in this row then skip the row
                      if (!row.cells.find((cell) => cell.value !== undefined)) {
                        continue;
                      }
                      const formulaCell = row.cells.find(
                        (cell) => cell.id === cellSchema.id
                      );
                      if (formulaCell) {
                        const result = flowinglyFormulaService.evaluate(
                          cellSchema.formulaConfig,
                          $scope.form.schema,
                          $scope.form.data,
                          tableSchema,
                          row
                        );
                        if (result !== formulaCell.value) {
                          formulaCell.value = result;
                        }
                      }
                    }
                  }
                  $scope.form.data[field.name] = JSON.stringify(tableData);
                }
              }
            }
            // update formulas on form
            else if (
              field.type === flowinglyConstants.formFieldType.FORMULA &&
              field.formulaConfig
            ) {
              const result = flowinglyFormulaService.evaluate(
                field.formulaConfig,
                $scope.form.schema,
                $scope.form.data
              );
              $scope.form.data[field.name] = result;
            }
          }
        }
        $scope.$apply();
      };

      $scope.setModifiedState = function (changedFiled) {
        changedFiled.state.modified = true;
      };
    };

    this.initForm = function (fgFormCtrl) {
      _form = fgFormCtrl ? fgFormCtrl.model : {};
      return _form;
    };

    this.initField = function (fieldSchema: IFormField, editMode: boolean) {
      _field = {
        $_id: 'id' + fgUtils.getUnique(),
        validationTypeName: showDataTypeInValidation(fieldSchema.type)
          ? fieldSchema.type
          : '',
        schema: fieldSchema,
        isVisible: editMode == true || !fieldSchema.hideByDefault,
        isHiddenByConditions: false
      };

      conditionalFormService.storeFieldAddedToUI(_field);

      $scope.$watch('field.schema.name', function (value, oldValue) {
        self.registerState(value);
      });
      $scope.$watch('field.schema.displayName', function (value, oldValue) {
        if (value !== oldValue)
          pubsubService.publish('WORKFLOW_DESIGNER_FORM_FIELDS_CHANGED', value);
      });
      $scope.$watch(
        function () {
          return _form.data[_field.schema.name];
        },
        function () {
          if (self.fieldChangeTimeout) {
            clearTimeout(self.fieldChangeTimeout);
          }
          self.fieldChangeTimeout = setTimeout(
            () => $scope.populateNotifyFields(_field),
            500
          );
        }
      );

      return _field;
    };

    this.initDefaultData = function (
      fieldSchema: IFormField,
      editMode: boolean
    ) {
      const fieldName = fieldSchema.name;

      _form.data = _form.data || {};

      if (editMode) {
        $scope.$watch('field.schema.value', function (value) {
          _form.data[fieldSchema.name] = value;
        });

        $scope.$watch('field.schema.name', function (value, oldValue) {
          if (value !== oldValue) {
            const data = _form.data[oldValue];
            delete _form.data[oldValue];
            _form.data[value] = data;
          }
        });
      } else if (
        _form.data &&
        _form.data[fieldName] === undefined &&
        fieldSchema.value !== undefined
      ) {
        _form.data[fieldName] = fieldSchema.value;
      }

      return _form.data;
    };

    this.getFieldSchema = function () {
      return _field.schema;
    };

    this.setFieldState = function (state) {
      // Called by the field-input directive
      _field.state = state;
      self.registerState(_field.schema.name);
    };

    this.registerState = function (fieldName: string) {
      // Re-register the ngModelCtrl with the form controller
      // whenever the name of the field has been modified.

      if (_form.state && _field.state) {
        _form.state.$removeControl(_field.state);
        _field.state.$name = fieldName;
        _form.state.$addControl(_field.state);
      }

      _field.name = fieldName;
    };

    this.field = function () {
      return _field;
    };

    this.form = function () {
      return _form;
    };

    function showDataTypeInValidation(type) {
      return (
        type === flowinglyConstants.formFieldType.NUMBER ||
        type === flowinglyConstants.formFieldType.CURRENCY ||
        type === flowinglyConstants.formFieldType.EMAIL ||
        type === flowinglyConstants.formFieldType.DATE
      );
    }

    function setNotifyFieldOptions(fieldName, values, tableColumnId) {
      const matchField = $scope.form.schema.fields.find(function (sf) {
        return sf.name === fieldName;
      });

      if (matchField) {
        if (matchField.type === flowinglyConstants.formFieldType.LOOKUP) {
          const tempArray = [];
          let watchVal;
          matchField.lookupConfig.userObject = [];
          const lookupValues = [];
          if (!$scope.form.values) {
            $scope.form.values = [];
          }

          for (const v of values) {
            let val;
            if (matchField.lookupConfig) {
              val = v.value;

              matchField.lookupConfig.userObject.push({
                value: v.value,
                text: v.text
              });
            } else {
              val = v.text;
            }
            lookupValues.push(val);
            tempArray.push(val);
            watchVal = tempArray.toString();
          }

          $scope.form.data[matchField.name] = tempArray.join(', ');
          $scope.form.values[matchField.name] = JSON.stringify(lookupValues);

          if (matchField.notifyFields !== undefined) {
            if ($scope.form.values && $scope.form.values[matchField.name]) {
              watchVal = $scope.form.values[matchField.name];
            }
            getLookupNotifyField(matchField, watchVal);
          }
        } else {
          if (
            matchField.type === flowinglyConstants.formFieldType.TABLE &&
            tableColumnId !== undefined
          ) {
            const tableSchema = JSON.parse(matchField.tableSchema);
            const matchColumn = tableSchema.find(function (c) {
              return c.id === tableColumnId;
            });

            if (matchColumn) {
              matchColumn.options = values;
              matchColumn.filteredOptions = angular.copy(matchColumn.options);
              matchField.tableSchema = JSON.stringify(tableSchema);
            }
            matchField.options = matchField.options || [];
            const columnOptions = matchField.options.find(
              (options) => options.tableColumnId === tableColumnId
            );
            if (columnOptions) {
              columnOptions.options = values;
            } else {
              matchField.options.push({
                tableColumnId: tableColumnId,
                options: values
              });
            }
          } else {
            matchField.options = values;
            matchField.filteredOptions = angular.copy(matchField.options);
          }
        }
      }
    }

    function getLookupNotifyField(matchField, watchVal) {
      const requestPayload = angular.copy(matchField.notifyFields, []);

      for (const field of requestPayload) {
        let watchFieldName = '';
        if (
          field.dbDataSource.filters &&
          field.dbDataSource.filters.length > 0
        ) {
          watchFieldName = field.dbDataSource.filters[0].value;
          field.dbDataSource.filters[0].value = watchVal;
        }

        const matchFieldLookup = $scope.form.schema.fields.find(function (sf) {
          return sf.name === field.fieldName;
        });

        if (matchFieldLookup) {
          field.Searchable = matchFieldLookup.searchable;
          field.SearchablePageSize = flowinglyConstants.searchableComboPageSize;
          if (watchFieldName) {
            const lookupFiled = $scope.form.schema.fields.find(function (sf) {
              return (
                sf.name === watchFieldName &&
                sf.type === flowinglyConstants.formFieldType.LOOKUP
              );
            });

            if (lookupFiled) {
              field.previousFieldLookUpConfig = lookupFiled.lookupConfig;
            }
          }
        }
        field.flowModelId = $scope.fieldSchema.publicForm;
      }
      const optionsPromise = fieldService
        .getFieldOptions($scope.fieldSchema.publicForm, requestPayload)
        .then(function (results) {
          results.forEach(function (result) {
            setNotifyFieldOptions(
              result.fieldName,
              result.options,
              result.tableColumnId
            );
          });
        });
      busyService.addPromise(optionsPromise);
    }
  }
]);

interface IFieldControllerField {
  $_id: string;
  validationTypeName: unknown;
  schema: IFormField;
  state?: INgModelController;
  isVisible: boolean;
  isHiddenByConditions: boolean;
  name?: string;
}
